import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../../components/seo';
import HeroLayout from '../../components/layouts/hero-layout';
import { HeroElement } from '../../components/layouts';
import { CategoryGrid } from '../../components/category-grid';
import { CategoryModel } from '../../models';

const useCategories = (): CategoryModel[] => {
  const {
    allMdx: { categories },
  } = useStaticQuery(graphql`
    query {
      allMdx(limit: 2000, filter: { frontmatter: { hidden: { ne: true } } }) {
        categories: group(field: frontmatter___category) {
          category: fieldValue
          count: totalCount
        }
      }
    }
  `);
  return categories;
};

export const CategoriesPage: React.FC = () => {
  const categories = useCategories();
  const hero = <HeroElement title="Categories" description="A collection of post series" />;

  return (
    <HeroLayout hero={hero}>
      <SEO title="Blog | Categories" description="A collection of posts series by Piotr Staniów" />
      <CategoryGrid categories={categories} />
    </HeroLayout>
  );
};

export default CategoriesPage;
