import React from 'react';
import { CategoryModel } from '../models';
import { kebabCase } from '../utils/kebab-case';
import { PostCardContainer } from './post-card/post-card-container';
import { PostCard } from './post-card';

interface CategoryGridProps {
  categories: CategoryModel[];
}

export const CategoryGrid: React.FC<CategoryGridProps> = ({ categories }) => (
  <PostCardContainer>
    {categories.map(({ category, count }) => (
      <PostCard
        key={category}
        path={`/blog/categories/${kebabCase(category)}`}
        title={category}
        description={`${count} post${count > 1 ? 's' : ''} in this series`}
      />
    ))}
  </PostCardContainer>
);
